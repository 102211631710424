@tailwind base;
@tailwind components;
@tailwind utilities;

.main {
  padding: 0;
  height: 100%;
}




body, html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #11151c; /* Replace with your desired dark blue color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes slideIn {
  from {
    transform: translateY(100%); /* Start from 100% down */
  }
  to {
    transform: translateY(0); /* End at the original position */
  }
}

/* Apply the animation to the element */
.slide-in {
  animation: slideIn 0.5s ease; /* Use the slideIn animation with a 0.5s duration and ease timing function */
}

@keyframes slideOut {
  from {
    transform: translateY(0); /* Start from the original position */
  }
  to {
    transform: translateY(100%); /* End 100% down */
  }
}

/* Apply the animation to the element */
.slide-out {
  animation: slideOut 0.5s ease; /* Use the slideOut animation with a 0.5s duration and ease timing function */
}

.fadeInLeft {
  animation: fadeInLeft 0.5s ease;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}